import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { Field } from 'formik'
import messages from '@utils/messages'
import { validateField } from '@utils/validators'
import LiquidInputAdapter from '@src/components/LiquidInputAdapter'

interface MobileSecurityCodeInputProps {
  isShowCode: boolean
  setIsShowCode: React.Dispatch<React.SetStateAction<boolean>>
  onClick: () => void
}

const MobileSecurityCodeInput: FC<MobileSecurityCodeInputProps> = ({
  isShowCode,
  setIsShowCode,
  onClick,
}) => {
  const intl = useIntl()
  const [time, setTime] = useState(60)

  useEffect(() => {
    if (!isShowCode) {
      return
    }
    const timer = setInterval(() => {
      setTime((preSecond) => {
        if (preSecond <= 1) {
          setIsShowCode(false)
          clearInterval(timer)
          return 60
        }
        return preSecond - 1
      })
    }, 1000)
    return () => clearInterval(timer)
  }, [isShowCode])

  return (
    <Field
      name="securityCode"
      component={LiquidInputAdapter}
      label={<FormattedMessage {...messages.MOBILE_SECURITY_CODE} />}
      validate={validateField(
        yup
          .string()
          .required(intl.formatMessage(messages.MOBILE_SECURITY_CODE_REQUIRED))
      )}
      endAdornment={
        isShowCode ? (
          <Box pr={2}>{time}s</Box>
        ) : (
          <Button onClick={onClick}>
            <FormattedMessage {...messages.RESEND_CODE} />
          </Button>
        )
      }
    />
  )
}

export default MobileSecurityCodeInput
