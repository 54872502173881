import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import messages from '@utils/messages'

interface RememberMeInfoModalProps {
  isOpen: boolean
  onClose(): void
}
const RememberMeInfoModal: FC<RememberMeInfoModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="RememberMeInfoModal"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="RememberMeInfoModal">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2">
            <FormattedMessage {...messages.REMEMBER_ME} />
          </Typography>
          <IconButton onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={5}>
          <FormattedMessage {...messages.REMEMBER_ME_INFO} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default RememberMeInfoModal
