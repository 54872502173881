// ttl is in milliseconds
export function setWithExpiry(key, value, ttl): void {
  try {
    const item = {
      value: value,
      expiry: Date.now() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  } catch (e) {}
}

export function getWithExpiry(key) {
  try {
    const itemStr = localStorage.getItem(key)
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    if (Date.now() > item.expiry) {
      localStorage.removeItem(key)
      return null
    }
    return item.value
  } catch (e) {
    return null
  }
}

export function rememberUserNameOrNot(rememberMeChecked, userName): void {
  try {
    if (rememberMeChecked) {
      setWithExpiry('userName', userName, 14 * 86400000)
    } else {
      localStorage.removeItem('userName')
    }
  } catch (e) {}
}
