// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type WechatLoginMutationVariables = Types.Exact<{
  code: Types.Scalars['String'];
}>;


export type WechatLoginMutation = { __typename?: 'Mutation', wechatLogin?: { __typename?: 'WechatLoginResponse', accessToken?: string | null } | null };


export const WechatLoginDocument = gql`
    mutation WechatLogin($code: String!) {
  wechatLogin(code: $code) {
    accessToken
  }
}
    `;
export type WechatLoginMutationFn = ApolloReactCommon.MutationFunction<WechatLoginMutation, WechatLoginMutationVariables>;
export type WechatLoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<WechatLoginMutation, WechatLoginMutationVariables>, 'mutation'>;

    export const WechatLoginComponent = (props: WechatLoginComponentProps) => (
      <ApolloReactComponents.Mutation<WechatLoginMutation, WechatLoginMutationVariables> mutation={WechatLoginDocument} {...props} />
    );
    

/**
 * __useWechatLoginMutation__
 *
 * To run a mutation, you first call `useWechatLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWechatLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wechatLoginMutation, { data, loading, error }] = useWechatLoginMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useWechatLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<WechatLoginMutation, WechatLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<WechatLoginMutation, WechatLoginMutationVariables>(WechatLoginDocument, options);
      }
export type WechatLoginMutationHookResult = ReturnType<typeof useWechatLoginMutation>;
export type WechatLoginMutationResult = ApolloReactCommon.MutationResult<WechatLoginMutation>;
export type WechatLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<WechatLoginMutation, WechatLoginMutationVariables>;