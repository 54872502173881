import React, { FC } from 'react'
import { Box } from '@material-ui/core'

interface MobileMaskProps {
  mobile: string
}

const MobileMask: FC<MobileMaskProps> = ({ mobile }) => {
  return (
    <Box display={'inline'} fontWeight={900}>
      {'+86'} {mobile?.replace(/^(\d{2})\d{5}(\d+)/, '$1*****$2')}
    </Box>
  )
}

export default MobileMask
