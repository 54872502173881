import React from 'react'
import {
  borders,
  compose,
  display,
  flexbox,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
  ComposedStyleFunction,
  PropsFor,
} from '@material-ui/system'
import { styled } from '@material-ui/core/styles'

export const styleFunction = compose(
  borders,
  display,
  flexbox,
  positions,
  palette,
  shadows,
  sizing,
  spacing,
  typography
)

type BoxStyleFunction = ComposedStyleFunction<
  [
    typeof borders,
    typeof display,
    typeof flexbox,
    typeof palette,
    typeof positions,
    typeof shadows,
    typeof sizing,
    typeof spacing,
    typeof typography,
  ]
>
type Omit<T, K extends keyof any> = T extends any
  ? Pick<T, Exclude<keyof T, K>>
  : never
type SystemProps = PropsFor<BoxStyleFunction>
type ElementProps = Omit<React.HTMLAttributes<HTMLElement>, keyof SystemProps>

export interface BoxProps extends ElementProps, SystemProps {
  // styled API
  component?: React.ElementType
  clone?: boolean
}

const Box: React.ComponentType<BoxProps> = styled('div')(styleFunction, {
  name: 'Box',
})

export default Box
