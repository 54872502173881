import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import { Field } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import LiquidInputAdapter from '@src/components/LiquidInputAdapter'
import messages from '@utils/messages'
import { validateField, isValidChinaMobile } from '@utils/validators'

const useStyles = makeStyles((theme: Theme) => ({
  mobileInput: {
    '& div.Mui-error': {
      borderWidth: '2px',
      boxShadow: 'none',
    },
  },

  countryCode: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#d6d6d6',
    height: 46,
    boxSizing: 'border-box',
    padding: '4px 12px 6px',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    [theme.breakpoints.up('sm')]: {
      height: 38,
    },
  },
}))

interface MobileLoginInputProps {
  name: string
  required: boolean
}

const MobileLoginInput: React.FC<MobileLoginInputProps> = ({
  name,
  required,
}) => {
  const classes = useStyles()
  const intl = useIntl()
  return (
    <Field
      name={name}
      component={LiquidInputAdapter}
      required={required}
      label={<FormattedMessage {...messages.CELL_PHONE} />}
      validate={validateField(
        yup
          .string()
          .required(intl.formatMessage(messages.MOBILE_LOGIN_REQUIRED))
          .test(
            'validChinaMobileNumber',
            intl.formatMessage(messages.MOBILE_FORMAT_INVALID),
            (value) => isValidChinaMobile(value)
          )
      )}
      rootClass={classes.mobileInput}
      startAdornment={
        <div className={classes.countryCode}>
          <span>+86</span>
        </div>
      }
    />
  )
}

export default MobileLoginInput
