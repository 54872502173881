// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type PatchSitePreferenceMutationVariables = Types.Exact<{
  data?: Types.InputMaybe<Array<Types.PatchSitePreferenceInput> | Types.PatchSitePreferenceInput>;
}>;


export type PatchSitePreferenceMutation = { __typename?: 'Mutation', patchSitePreference?: boolean | null };


export const PatchSitePreferenceDocument = gql`
    mutation PatchSitePreference($data: [PatchSitePreferenceInput!]) {
  patchSitePreference(data: $data)
}
    `;
export type PatchSitePreferenceMutationFn = ApolloReactCommon.MutationFunction<PatchSitePreferenceMutation, PatchSitePreferenceMutationVariables>;
export type PatchSitePreferenceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PatchSitePreferenceMutation, PatchSitePreferenceMutationVariables>, 'mutation'>;

    export const PatchSitePreferenceComponent = (props: PatchSitePreferenceComponentProps) => (
      <ApolloReactComponents.Mutation<PatchSitePreferenceMutation, PatchSitePreferenceMutationVariables> mutation={PatchSitePreferenceDocument} {...props} />
    );
    

/**
 * __usePatchSitePreferenceMutation__
 *
 * To run a mutation, you first call `usePatchSitePreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchSitePreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchSitePreferenceMutation, { data, loading, error }] = usePatchSitePreferenceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePatchSitePreferenceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PatchSitePreferenceMutation, PatchSitePreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<PatchSitePreferenceMutation, PatchSitePreferenceMutationVariables>(PatchSitePreferenceDocument, options);
      }
export type PatchSitePreferenceMutationHookResult = ReturnType<typeof usePatchSitePreferenceMutation>;
export type PatchSitePreferenceMutationResult = ApolloReactCommon.MutationResult<PatchSitePreferenceMutation>;
export type PatchSitePreferenceMutationOptions = ApolloReactCommon.BaseMutationOptions<PatchSitePreferenceMutation, PatchSitePreferenceMutationVariables>;