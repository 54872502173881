import React, { useEffect, useState } from 'react'
import { FormattedMessage, IntlShape } from 'react-intl'
import { forgotPasswordRoute, useRouter } from '@src/routes'
import { Link } from '@src/components/Link'
import { makeStyles } from '@material-ui/core/styles'
import {
  Theme,
  Divider,
  Checkbox,
  Box,
  InputAdornment,
  Button,
  Typography,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { Field, Form, FormikProps } from 'formik'
import {
  sendLoginPageEvent,
  sendSignInInteractionEvents,
} from '@utils/analytics'
import { useCookies } from '@src/utils/cookies'
import LiquidInputAdapter from '@src/components/LiquidInputAdapter'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import messages from '@utils/messages'
import { LoginFormValues } from './LoginForm'
import vrStyles from '@src/styles/utils/vrStyles'
import { validateField } from '@utils/validators'
import * as yup from 'yup'
import WechatLogin from '@src/routes/Login/WechatLogin'
import { useChinaUser } from '@src/utils/useChinaUser'
import useLoginRedirect from '@utils/useLoginRedirect'
import RememberMeInfoModal from '@src/routes/Login/RememberMeInfoModal'
import LiquidCheckboxAdapter from '@src/components/LiquidCheckboxAdapter/LiquidCheckboxAdapter'
import ResponsiveModal, {
  ResponsiveModalBody,
  ModalSizes,
} from '@src/components/ResponsiveModal/ResponsiveModal'
import LinkStyleButton from '@src/components/LinkStyleButton'

const { vr4 } = vrStyles
const useStyles = makeStyles((theme: Theme) => ({
  forgotPassword: {
    display: 'block',
    textAlign: 'right',
    ...vr4,
  },
  listItemRoot: {
    display: 'block',
    padding: 0,
    ...vr4,
  },
  submitError: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: '8px 16px 10px',
    marginBottom: theme.spacing(5),
  },
  wechatRow: {
    marginBottom: 0,
  },
  infoIcon: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    marginLeft: '2px',
  },
  keepMeSignedInRow: {
    '& > label': { marginRight: 0 },
  },
  checkboxLabel: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: 7,
  },
  checkboxInfoTrigger: {
    textTransform: 'capitalize',
    verticalAlign: 'top',
    lineHeight: 1.5,
    marginTop: 7,
    marginLeft: theme.spacing(2),
  },
  modalBody: {
    '& > p + p': { marginTop: '1em' },
  },
  showHideButton: {
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(3),
    minWidth: '44px',
  },
}))
interface Props {
  formikProps: FormikProps<LoginFormValues>
  submitSuccess: boolean
  setLoginError(loginError: string): void
  redirectToForgotPassword: boolean
  intl: IntlShape
  agreeClicked: boolean | null
  setTerms: Function
  setTcUpdateOpen: Function
  rememberMeChecked?: boolean
  setRememberMeChecked?: React.Dispatch<React.SetStateAction<boolean>>
  allowPersistentLogin?: boolean
  useradLink?: string
}

const LoginFormBody: React.FC<Props> = ({
  formikProps,
  redirectToForgotPassword,
  submitSuccess,
  intl,
  setLoginError,
  agreeClicked,
  setTerms,
  setTcUpdateOpen,
  rememberMeChecked,
  setRememberMeChecked,
  allowPersistentLogin,
  useradLink,
}) => {
  const router = useRouter()
  const isChinaUser = useChinaUser()
  const classes = useStyles()
  const [cookies] = useCookies(['_ga'])
  const listItemClasses = {
    root: classes.listItemRoot,
  }
  const [rememberMeInfoModalOpen, setRememberMeInfoModalOpen] = useState(false)
  const [keepMeSignedInModalOpen, setKeepMeSignedInModalOpen] = useState(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  useLoginRedirect(
    submitSuccess,
    setLoginError,
    'password-login',
    agreeClicked,
    setTerms,
    setTcUpdateOpen,
    useradLink
  )
  useEffect(() => {
    if (redirectToForgotPassword) {
      router.push(
        {
          pathname: forgotPasswordRoute.index(),
          query: {
            passwordExpired: true,
          },
        },
        forgotPasswordRoute.index()
      )
    }
  }, [redirectToForgotPassword, router])

  const checkRememberMe = (event) => {
    setRememberMeChecked && setRememberMeChecked(event.target.checked)
  }

  return (
    <Form method="POST">
      <List component="div" disablePadding>
        <ListItem component="div" classes={listItemClasses}>
          <Field
            name="userName"
            component={LiquidInputAdapter}
            label={<FormattedMessage {...messages.USERNAME_EMAIL} />}
            validate={validateField(
              yup
                .string()
                .required(
                  intl.formatMessage(messages.EMAIL_USERNAME_LOGIN_REQUIRED)
                )
            )}
            data-testid="user-name"
            inputProps={{
              'data-scrub': 'true',
              autoComplete: 'username',
            }}
          />
        </ListItem>
        <ListItem component="div" classes={listItemClasses}>
          <Field
            name="password"
            component={LiquidInputAdapter}
            type={isPasswordVisible ? 'text' : 'password'}
            label={<FormattedMessage {...messages.PASSWORD} />}
            validate={validateField(
              yup
                .string()
                .required(intl.formatMessage(messages.PASSWORD_LOGIN_REQUIRED))
            )}
            endAdornment={
              <InputAdornment position="end">
                <LinkStyleButton
                  className={classes.showHideButton}
                  type="button"
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                >
                  {isPasswordVisible ? (
                    <FormattedMessage {...messages.HIDE} />
                  ) : (
                    <FormattedMessage {...messages.SHOW} />
                  )}
                </LinkStyleButton>
              </InputAdornment>
            }
            data-testid="password"
            inputProps={{
              'data-scrub': 'true',
              autoComplete: 'current-password',
            }}
          />
        </ListItem>
        <ListItem component="div" classes={listItemClasses}>
          {/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
          <Link href={forgotPasswordRoute.index()}>
            <a
              className={classes.forgotPassword}
              onClick={() => {
                sendLoginPageEvent({
                  gaId: cookies._ga,
                  actionTitle: 'Forgot password',
                })
                sendSignInInteractionEvents({
                  action: 'visit forget password page',
                  section: 'sign in',
                  element_type: 'link',
                  element_text: 'forget password',
                  link_url: forgotPasswordRoute.index(),
                })
              }}
            >
              <FormattedMessage
                id="FORGOT_PASSWORD"
                defaultMessage="Forgot Password"
              />
              ?
            </a>
          </Link>
        </ListItem>
        <ListItem component="div" classes={listItemClasses}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formikProps.isSubmitting}
            fullWidth
            size="large"
            data-testid="login-page-sign-in-button"
            id="login-page-sign-in-button"
          >
            {!isChinaUser ? (
              <FormattedMessage {...messages.SIGN_IN_PROFILE} />
            ) : (
              <FormattedMessage id="SIGN_IN" defaultMessage="Sign In" />
            )}
          </Button>
        </ListItem>
        {allowPersistentLogin && (
          <ListItem
            component="div"
            classes={listItemClasses}
            className={classes.keepMeSignedInRow}
          >
            <Field
              name="rememberMe"
              id="rememberMe"
              component={LiquidCheckboxAdapter}
              label={<FormattedMessage {...messages.KEEP_ME_SIGNED_IN} />}
              formControlClasses={{ label: classes.checkboxLabel }}
            />
            <LinkStyleButton
              className={classes.checkboxInfoTrigger}
              onClick={() => {
                sendSignInInteractionEvents({
                  action: 'keep me sign in detail',
                  section: 'sign in',
                  element_type: 'link',
                  element_text: 'details',
                })
                setKeepMeSignedInModalOpen(true)
              }}
            >
              <FormattedMessage {...messages.DETAILS} />
            </LinkStyleButton>
          </ListItem>
        )}
        {isChinaUser && (
          <>
            <ListItem component="div" classes={listItemClasses}>
              <Box display={'flex'} alignItems={'center'}>
                <Checkbox
                  color="primary"
                  checked={rememberMeChecked}
                  onChange={checkRememberMe}
                />
                <FormattedMessage {...messages.REMEMBER_ME} />
                <InfoIcon
                  className={classes.infoIcon}
                  onClick={() => setRememberMeInfoModalOpen(true)}
                />
              </Box>
            </ListItem>
            <ListItem
              component="div"
              classes={listItemClasses}
              className={classes.wechatRow}
            >
              <Divider light />
              <WechatLogin
                setLoginError={setLoginError}
                agreeClicked={agreeClicked}
                setTerms={setTerms}
                setTcUpdateOpen={setTcUpdateOpen}
              />
            </ListItem>
            <RememberMeInfoModal
              isOpen={rememberMeInfoModalOpen}
              onClose={() => setRememberMeInfoModalOpen(false)}
            />
          </>
        )}
      </List>

      <ResponsiveModal
        open={keepMeSignedInModalOpen}
        onClose={() => setKeepMeSignedInModalOpen(false)}
        size={ModalSizes.Small}
        renderTitle={() => (
          <Typography variant="h2">
            <FormattedMessage {...messages.KEEP_ME_SIGNED_IN_CHECKBOX} />
          </Typography>
        )}
      >
        <ResponsiveModalBody className={classes.modalBody}>
          <Typography variant="body2">
            <FormattedMessage {...messages.KEEP_ME_SIGNED_IN_LINE_1} />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage {...messages.KEEP_ME_SIGNED_IN_LINE_2} />
          </Typography>
        </ResponsiveModalBody>
      </ResponsiveModal>
    </Form>
  )
}

export default LoginFormBody
