import {
  Box,
  ButtonBase,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useUserSession } from '@src/utils/useUserSession'
import { useWechatLoginMutation } from '@src/mutations/WechatLoginMutation.generated'
import { OriginalError, extractData } from '@src/utils/errorHandler'
import messages from '@src/utils/messages'
import { FormattedMessage, useIntl } from 'react-intl'
import { registerRoute, useRouter } from '@src/routes'
import storeValues from '@src/utils/storeValues'
import getConfig from 'next/config'
import { sendWechatLoginEvent } from '@utils/tealiumAnalytics'
import { useCookies } from '@utils/cookies'
import { sessionStorage } from 'react-storage'
import { EventKeys } from '@utils/analytics/enums'
import useLoginRedirect, { LOGIN_REDIRECT } from '@utils/useLoginRedirect'

const useStyles = makeStyles((theme: Theme) => ({
  wechatRow: {
    marginBottom: 0,
  },
  wechatImg: {
    padding: theme.spacing(2),
    height: theme.spacing(12),
    width: theme.spacing(12),
    borderRadius: theme.spacing(6),
  },
}))

const {
  publicRuntimeConfig: {
    wechat: { appId, redirectURL, QRCodeURL },
  },
} = getConfig()

const WechatLogin: React.FC<{
  setLoginError: (loginError: string) => void
  agreeClicked: boolean | null
  setTerms: Function
  setTcUpdateOpen: Function
}> = ({ setLoginError, agreeClicked, setTerms, setTcUpdateOpen }) => {
  const classes = useStyles()
  const [cookies] = useCookies(['_ga'])
  const router = useRouter()
  const { userSessionActions } = useUserSession()
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  // wechat-login is tracked in analytics.tsx
  useLoginRedirect(
    submitSuccess,
    setLoginError,
    '',
    agreeClicked,
    setTerms,
    setTcUpdateOpen
  )
  const { wechatLoginStatus, code, redirect } = router.query
  if (redirect) {
    sessionStorage.setItem<string>(LOGIN_REDIRECT, redirect)
  }
  /* Wechat QRCode redirect uri ONLY ACCEPT HTTPS  */
  /* For debug: press ESC to cancel the redirection and change the host to 'localhost:3000' */
  const wechatRedirectUri = encodeURIComponent(redirectURL)
  const wechatQRCodeUrl =
    QRCodeURL +
    Object.entries({
      appid: appId,
      redirect_uri: wechatRedirectUri,
      response_type: 'code',
      scope: 'snsapi_login',
    })
      .map(([key, value]) => key + '=' + value)
      .join('&')

  const [wechatLoginMutation] = useWechatLoginMutation()

  const showMessageUnableToLogin = () => {
    const message = intl.formatMessage(messages.USER_UNABLE_TO_LOGIN)
    setLoginError(message)
  }

  const wechatLogin = async () => {
    try {
      const { data } = await wechatLoginMutation({
        variables: {
          code,
        },
      })

      if (data?.wechatLogin?.accessToken) {
        sessionStorage.setItem<boolean>(EventKeys.IS_WECHAT_LOGGED_IN, true)
        await userSessionActions.login(
          data?.wechatLogin?.accessToken,
          storeValues.sial,
          true
        )
        setSubmitSuccess(true)
      }
    } catch (error) {
      const parsedError = extractData(error as OriginalError)
      if (parsedError.hasError('WECHAT_NOT_BIND')) {
        const unionId = parsedError?.errors?.[0].paramList?.[0]
        if (unionId) {
          router.push({
            pathname: registerRoute.linkWechat(),
            query: {
              unionId,
            },
          })
        } else {
          setLoading(false)
          showMessageUnableToLogin()
        }
      } else {
        setLoading(false)
        showMessageUnableToLogin()
      }
    }
  }

  useEffect(() => {
    if (wechatLoginStatus === 'loading') {
      wechatLogin()
      setLoading(true)
    }
  }, [wechatLoginStatus])

  return (
    <Box>
      <Box display="flex" padding={loading ? 4 : 0} justifyContent="center">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <ButtonBase
              aria-label="Sign In with WeChat"
              onClick={() => {
                sendWechatLoginEvent(cookies._ga)
                window.location.href = wechatQRCodeUrl
              }}
            >
              <img
                className={classes.wechatImg}
                src="/static/wechat/wechat_icon.svg"
                alt="WeChat logo"
              />
              <Typography variant="body2" color="primary">
                <FormattedMessage {...messages.WECHAT} />
              </Typography>
            </ButtonBase>
          </>
        )}
      </Box>
    </Box>
  )
}

export default WechatLogin
