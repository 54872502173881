import React, { useState } from 'react'
import { FormattedMessage, IntlShape } from 'react-intl'
import { Form, FormikProps } from 'formik'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { Divider } from '@material-ui/core'
import messages from '@utils/messages'
import { isValidChinaMobile } from '@utils/validators'
import { MobileLoginFormValues } from '@src/routes/Login/LoginForm'
import { useGetMobileSecurityCodeMutation } from '@src/mutations/GetMobileSecurityCodeMutation.generated'
import WechatLogin from '@src/routes/Login/WechatLogin'
import MobileLoginInput from '@src/components/MobileLoginInput'
import { OriginalError, extractData } from '@utils/errorHandler'
import MobileMask from '@src/components/MobileMask'
import MobileSecurityCodeInput from '@src/components/MobileSecurityCodeInput'
import useLoginRedirect from '@utils/useLoginRedirect'

interface Props {
  formikProps: FormikProps<MobileLoginFormValues>
  submitSuccess: boolean
  setLoginError(loginError: string): void
  setLoginWarning(loginWarning: string): void
  setTabValue(value: number): void
  intl: IntlShape
  agreeClicked: boolean | null
  setTerms: Function
  setTcUpdateOpen: Function
}
const MobileLoginBody: React.FC<Props> = ({
  formikProps,
  submitSuccess,
  intl,
  setLoginError,
  setLoginWarning,
  setTabValue,
  agreeClicked,
  setTerms,
  setTcUpdateOpen,
}) => {
  const [getMobileSecurityCode] = useGetMobileSecurityCodeMutation()
  const [continueClicked, setContinueClicked] = useState(false)
  const [isShowCode, setIsShowCode] = useState<boolean>(false)
  useLoginRedirect(
    submitSuccess,
    setLoginError,
    'mobile-login',
    agreeClicked,
    setTerms,
    setTcUpdateOpen
  )

  const getSecurityCode = async (mobile): Promise<boolean> => {
    try {
      await getMobileSecurityCode({ variables: { mobile } })
      return true
    } catch (error) {
      const { errors } = extractData(error as OriginalError)
      if (errors.length > 0) {
        const { code } = errors[0]
        if (code === 'MOBILE_IS_NOT_VERIFIED') {
          setLoginWarning(intl.formatMessage(messages[code]))
          setTabValue(0)
        } else {
          const errorMsg = messages[code]
            ? intl.formatMessage(messages[code])
            : intl.formatMessage(messages.GENERIC_ERROR)
          setLoginError(errorMsg)
        }
      }
      return false
    }
  }

  const handleContinue = async () => {
    setLoginError('')
    setLoginWarning('')
    const mobile = formikProps.values.mobile
    formikProps.setTouched({ mobile: true }, true)
    formikProps.validateField('mobile')

    const isInvalidMobile = mobile === '' || !isValidChinaMobile(mobile)

    if (isInvalidMobile) {
      return
    }
    //send request to get 6-digit code
    const res = await getSecurityCode(mobile)
    if (!res) return
    setIsShowCode(true)
    setContinueClicked(true)
  }

  const handleResend = async (mobile) => {
    setLoginError('')
    setLoginWarning('')
    const res = await getSecurityCode(mobile)
    if (!res) return
    setIsShowCode(true)
  }

  const handleChange = () => {
    setContinueClicked(false)
  }

  return (
    <Form method="POST">
      {!continueClicked ? (
        <Box>
          <Box mb={4}>
            <FormattedMessage {...messages.MOBILE_INFO} />
          </Box>
          <Box mb={4}>
            <MobileLoginInput name="mobile" required={false} />
          </Box>
          <Box mb={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              onClick={handleContinue}
            >
              <FormattedMessage {...messages.CONTINUE} />
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box mb={4}>
            <FormattedMessage {...messages.MOBILE_SECURITY_CODE_SENT} />
          </Box>
          <Box mb={4}>
            <MobileMask mobile={formikProps.values?.mobile} />
            <Button onClick={handleChange}>
              <FormattedMessage {...messages.CHANGE_MOBILE} />
            </Button>
          </Box>
          <Box mb={4}>
            <MobileSecurityCodeInput
              isShowCode={isShowCode}
              setIsShowCode={setIsShowCode}
              onClick={() => handleResend(formikProps.values?.mobile)}
            />
          </Box>
          <Box mb={4}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={formikProps.isSubmitting}
              fullWidth
              size="large"
              data-testid="login-page-sign-in-button"
              id="login-page-sign-in-button"
            >
              <FormattedMessage id="SIGN_IN" defaultMessage="Sign In" />
            </Button>
          </Box>
        </Box>
      )}

      <Box>
        <Divider light />
        <WechatLogin
          setLoginError={setLoginError}
          agreeClicked={agreeClicked}
          setTerms={setTerms}
          setTcUpdateOpen={setTcUpdateOpen}
        />
      </Box>
    </Form>
  )
}

export default MobileLoginBody
