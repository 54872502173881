// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type UpdateTermConditionMutationVariables = Types.Exact<{
  terms: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type UpdateTermConditionMutation = { __typename?: 'Mutation', updateTermCondition?: { __typename?: 'TermConditionResponse', requireTermConditionUpdate: boolean, terms: Array<{ __typename?: 'termConditionItem', termUrl: string, termKey: string, agree: boolean } | null> } | null };


export const UpdateTermConditionDocument = gql`
    mutation UpdateTermCondition($terms: [String!]!) {
  updateTermCondition(terms: $terms) {
    terms {
      termUrl
      termKey
      agree
    }
    requireTermConditionUpdate
  }
}
    `;
export type UpdateTermConditionMutationFn = ApolloReactCommon.MutationFunction<UpdateTermConditionMutation, UpdateTermConditionMutationVariables>;
export type UpdateTermConditionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateTermConditionMutation, UpdateTermConditionMutationVariables>, 'mutation'>;

    export const UpdateTermConditionComponent = (props: UpdateTermConditionComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateTermConditionMutation, UpdateTermConditionMutationVariables> mutation={UpdateTermConditionDocument} {...props} />
    );
    

/**
 * __useUpdateTermConditionMutation__
 *
 * To run a mutation, you first call `useUpdateTermConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTermConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTermConditionMutation, { data, loading, error }] = useUpdateTermConditionMutation({
 *   variables: {
 *      terms: // value for 'terms'
 *   },
 * });
 */
export function useUpdateTermConditionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTermConditionMutation, UpdateTermConditionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateTermConditionMutation, UpdateTermConditionMutationVariables>(UpdateTermConditionDocument, options);
      }
export type UpdateTermConditionMutationHookResult = ReturnType<typeof useUpdateTermConditionMutation>;
export type UpdateTermConditionMutationResult = ApolloReactCommon.MutationResult<UpdateTermConditionMutation>;
export type UpdateTermConditionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTermConditionMutation, UpdateTermConditionMutationVariables>;