// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type GetMobileSecurityCodeMutationVariables = Types.Exact<{
  mobile: Types.Scalars['String'];
}>;


export type GetMobileSecurityCodeMutation = { __typename?: 'Mutation', getMobileSecurityCode?: boolean | null };


export const GetMobileSecurityCodeDocument = gql`
    mutation GetMobileSecurityCode($mobile: String!) {
  getMobileSecurityCode(mobile: $mobile)
}
    `;
export type GetMobileSecurityCodeMutationFn = ApolloReactCommon.MutationFunction<GetMobileSecurityCodeMutation, GetMobileSecurityCodeMutationVariables>;
export type GetMobileSecurityCodeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GetMobileSecurityCodeMutation, GetMobileSecurityCodeMutationVariables>, 'mutation'>;

    export const GetMobileSecurityCodeComponent = (props: GetMobileSecurityCodeComponentProps) => (
      <ApolloReactComponents.Mutation<GetMobileSecurityCodeMutation, GetMobileSecurityCodeMutationVariables> mutation={GetMobileSecurityCodeDocument} {...props} />
    );
    

/**
 * __useGetMobileSecurityCodeMutation__
 *
 * To run a mutation, you first call `useGetMobileSecurityCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetMobileSecurityCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getMobileSecurityCodeMutation, { data, loading, error }] = useGetMobileSecurityCodeMutation({
 *   variables: {
 *      mobile: // value for 'mobile'
 *   },
 * });
 */
export function useGetMobileSecurityCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetMobileSecurityCodeMutation, GetMobileSecurityCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GetMobileSecurityCodeMutation, GetMobileSecurityCodeMutationVariables>(GetMobileSecurityCodeDocument, options);
      }
export type GetMobileSecurityCodeMutationHookResult = ReturnType<typeof useGetMobileSecurityCodeMutation>;
export type GetMobileSecurityCodeMutationResult = ApolloReactCommon.MutationResult<GetMobileSecurityCodeMutation>;
export type GetMobileSecurityCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<GetMobileSecurityCodeMutation, GetMobileSecurityCodeMutationVariables>;