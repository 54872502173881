import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useUserSession } from '@utils/useUserSession'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import getConfig from 'next/config'
import {
  Button,
  Typography,
  Theme,
  Grid,
  useMediaQuery,
} from '@material-ui/core'
import messages from '@utils/messages'
import ResponsiveModal, { ModalSizes } from '@src/components/ResponsiveModal'
import { Field, Form, Formik } from 'formik'
import LiquidCheckboxAdapter from '@src/components/LiquidCheckboxAdapter'
import { useCompanyName } from '@src/utils/useCompanyName'
import { validateField } from '@utils/validators'
import TermsAndConditionsLabel from '@src/routes/Register/TermsAndConditionsLabel'
import clsx from 'clsx'
import { useRouter, staticContent } from '@src/routes'
import * as yup from 'yup'
import { sendLoginErrorEvent } from '@src/utils/analytics'
import { Link } from '@src/components/Link'
import { usePatchSitePreferenceMutation } from '@src/mutations/AcceptSitePreferenceMutation.generated'
import { useKoreaUser, useCountryUser } from '@src/utils/useChinaUser'

const {
  publicRuntimeConfig: {
    featureFlags: { aemCms },
  },
} = getConfig()

export const useStyles = makeStyles((theme: Theme) => {
  return {
    bodyText: {
      fontSize: theme.typography.pxToRem(14),
    },
    h2: {
      paddingBottom: '8px',
    },
    modalBody: {
      overflowY: 'auto',
      padding: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        padding: '15px 32px 30px 32px',
      },
    },
    button: {
      alignSelf: 'flex-end',
      minWidth: '100%',
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up('sm')]: {
        minWidth: '144px',
        marginBottom: 0,
        marginRight: theme.spacing(4),
      },
    },
    cancelButton: {
      [theme.breakpoints.up('sm')]: {},
    },
    image: {
      width: '100%',
      height: 'auto',
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
        height: 'auto',
      },
    },
    explainer: {
      paddingBottom: '12px',
    },
    acceptTerms: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    acceptTermsLabel: {
      fontSize: '1rem',
      lineHeight: '24px',
      display: 'inline-block',
    },
    buttonGroup: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '24px',
      },
    },
  }
})
interface PatchSitePreference {
  piiDataConsentAcceptanceFlag: boolean | null | undefined
  isGeneralTermsConditionDateExpiredFlag: boolean | null | undefined
  isSiteUseTermsDateExpiredFlag: boolean | null | undefined
  accessToken: string
}
interface LoginModalProps {
  open: boolean
  setModalOpen: (open: boolean) => void
  setLoginError: (string) => void
  participantId: string
  accessToken: string
  userName: string
  rememberMe?: boolean
  completeLogin: (accessToken, userName, rememberMe) => void
  patchSitePreferences: PatchSitePreference
}
interface FormValues {
  acceptTerms: boolean
  consentTerms: boolean
}

interface PayloadArray {
  op: string
  path: string
  value: boolean
}

const LoginModal: React.FC<LoginModalProps> = ({
  open,
  setModalOpen,
  accessToken,
  userName,
  rememberMe,
  completeLogin,
  setLoginError,
  patchSitePreferences,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const intl = useIntl()
  const { userSession } = useUserSession()
  const companyName = useCompanyName()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')) === true
  const [patchSitePreference] = usePatchSitePreferenceMutation()
  const { userSessionActions } = useUserSession()
  const router = useRouter()
  const isKoreaUser = useKoreaUser()
  const isTHUser = useCountryUser('TH')
  const isVNUser = useCountryUser('VN')
  const isPHUser = useCountryUser('PH')

  // Initial Form Values
  const INITIAL_VALUES = {
    acceptTerms: false,
    consentTerms: false,
  }

  const handleSubmit = async (formValues: FormValues, actions) => {
    const dataArray: PayloadArray[] = []
    dataArray.push({
      op: 'accessToken',
      path: patchSitePreferences && patchSitePreferences.accessToken,
      value: true,
    })
    if (
      formValues.acceptTerms &&
      formValues.consentTerms &&
      (isKoreaUser || isTHUser || isPHUser || isVNUser)
    ) {
      dataArray.push(
        {
          op: 'replace',
          path: '/generalTermsCondition',
          value: true,
        },
        {
          op: 'replace',
          path: '/siteAgreement',
          value: true,
        },
        {
          op: 'replace',
          path: '/piiDataConsentAcceptance',
          value: true,
        }
      )
    } else if (
      formValues.consentTerms &&
      (isKoreaUser || isTHUser || isPHUser || isVNUser)
    ) {
      dataArray.push({
        op: 'replace',
        path: '/piiDataConsentAcceptance',
        value: true,
      })
    } else {
      dataArray.push(
        {
          op: 'replace',
          path: '/generalTermsCondition',
          value: true,
        },
        {
          op: 'replace',
          path: '/siteAgreement',
          value: true,
        }
      )
    }

    try {
      const res = await patchSitePreference({
        variables: {
          data: dataArray,
        },
      })
      if (res.data) {
        completeLogin(accessToken, userName, rememberMe)
      } else {
        await userSessionActions.logout(router)
      }
    } catch (error) {
      const errorKey = messages.USER_UNABLE_TO_LOGIN
      sendLoginErrorEvent({ message: errorKey.defaultMessage })
      const errorMsg = intl.formatMessage(errorKey)
      actions.setSubmitting(false)
      await userSessionActions.logout(router)
      setLoginError(errorMsg)
      setModalOpen(false)
    }
  }

  function renderTitle() {
    return (
      <Typography component="h2" variant="h2" className={classes.h2}>
        <FormattedMessage {...messages.TERMS_AND_CONDITIONS} />
      </Typography>
    )
  }

  const continueAsGuest = async () => {
    await userSessionActions.logout(router)
    setModalOpen(false)
  }

  return (
    <>
      {open ? (
        <ResponsiveModal
          open
          size={ModalSizes.Medium}
          onClose={continueAsGuest}
          aria-labelledby="how-to-use-scan-now"
          renderTitle={renderTitle}
          raisedCloseButton
        >
          <Grid container className={classes.modalBody}>
            <Grid item xs={12}>
              <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
                {() => (
                  <Form>
                    <div>
                      <Typography className={classes.explainer}>
                        <FormattedMessage
                          {...messages.TERMS_AND_CONDITIONS_MODAL_EXPLANATION}
                        />
                      </Typography>
                      <div className={classes.acceptTerms}>
                        {patchSitePreferences.isGeneralTermsConditionDateExpiredFlag && (
                          <Field
                            name="acceptTerms"
                            component={LiquidCheckboxAdapter}
                            color="secondary"
                            validate={validateField(
                              yup
                                .boolean()
                                .oneOf(
                                  [true],
                                  intl.formatMessage(
                                    messages.MUST_ACCEPT_TERMS_OR_CONTINUE_AS_GUEST
                                  )
                                )
                            )}
                            label={
                              <TermsAndConditionsLabel
                                userSession={userSession}
                                companyName={companyName}
                                className={classes.acceptTermsLabel}
                                showAsterisk
                                order={'first'}
                              />
                            }
                          />
                        )}
                        {!patchSitePreferences.piiDataConsentAcceptanceFlag &&
                          isKoreaUser && (
                            <Field
                              name="consentTerms"
                              component={LiquidCheckboxAdapter}
                              color="secondary"
                              validate={validateField(
                                yup
                                  .boolean()
                                  .oneOf(
                                    [true],
                                    intl.formatMessage(
                                      messages.MUST_ACCEPT_KOREA_COLLECTION
                                    )
                                  )
                              )}
                              label={
                                <Typography
                                  variant="body2"
                                  component="span"
                                  className={classes.acceptTermsLabel}
                                >
                                  <FormattedMessage
                                    {...messages.REGISTRATION_INFO_KOREA_USERINFO_COLLECTION_CONSENT}
                                    values={{
                                      consentLink: (
                                        <Link
                                          {...staticContent.index({
                                            path: aemCms
                                              ? '/life-science/legal/personal-information-collection-consent-letter'
                                              : '/privacy-policy',
                                            language: userSession.language,
                                            country: userSession.country,
                                          })}
                                          passHref
                                        >
                                          <a rel="noopener" target="_blank">
                                            <FormattedMessage
                                              {...messages.REGISTRATION_INFO_KOREA_COLLECTION_PAGE}
                                            />
                                          </a>
                                        </Link>
                                      ),
                                    }}
                                  />{' '}
                                </Typography>
                              }
                            />
                          )}
                        {!patchSitePreferences.piiDataConsentAcceptanceFlag &&
                          (isTHUser || isPHUser || isVNUser) && (
                            <Field
                              name="consentTerms"
                              component={LiquidCheckboxAdapter}
                              color="secondary"
                              validate={validateField(
                                yup
                                  .boolean()
                                  .oneOf(
                                    [true],
                                    intl.formatMessage(
                                      messages.MUST_ACCEPT_PRIVACY_POLICY
                                    )
                                  )
                              )}
                              label={
                                <FormattedMessage
                                  id={'MERCK_PRIVACY_POLICY_SPECIFIC_COUNTRY'}
                                  defaultMessage="* I have read and understood the {mercksPrivacyPolicyLink} and consent to the processing of my personal data according to its terms and conditions. I further consent to receive information about Merck’s products which may be relevant to my interests."
                                  values={{
                                    mercksPrivacyPolicyLink: (
                                      <Link
                                        {...staticContent.index({
                                          path: aemCms
                                            ? '/life-science/legal/privacy-statement'
                                            : '/privacy-policy',
                                          language: userSession.language,
                                          country: userSession.country,
                                        })}
                                        passHref
                                      >
                                        <a rel="noopener" target="_blank">
                                          <FormattedMessage
                                            {...messages.PRIVACY_STATEMENT}
                                          />
                                        </a>
                                      </Link>
                                    ),
                                  }}
                                />
                              }
                            />
                          )}
                      </div>
                    </div>

                    <div className={classes.buttonGroup}>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={clsx(classes.button, classes.cancelButton)}
                        size="large"
                        onClick={continueAsGuest}
                        fullWidth={!isDesktop}
                      >
                        <FormattedMessage {...messages.CONTINUE_AS_GUEST} />
                      </Button>
                      <Button
                        type="submit"
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth={!isDesktop}
                      >
                        <FormattedMessage {...messages.SUBMIT} />
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </ResponsiveModal>
      ) : null}
    </>
  )
}

export default LoginModal
